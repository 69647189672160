.form-container {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
  height: calc(100vh - 200px);
  background-color: white;
  border-radius: 12px;
}

.select {
  height: 40px;
  display: flex;
  border: 1px #E8E8E8;
  align-items: center;
}

.text-field {
  height: 40px;
  display: flex;
  border: 1px #E8E8E8;
  align-items: center;
}

