.leaflet-container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 89vh;
  z-index: 1;
}
